.analytics-plans-sidebar {
    /* width: 300px;
    max-width: 370px; */
    height: auto; /* Adjust height automatically based on content */
    min-height: 138vh;
    background-color: #fff;
    color: #323232;
    transition: width 0.7s ease;
    position: relative;
    /* box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1); */
    z-index: 1;
  }
  
  .analytics-plans-sidebar.closed {
    /* width: 100px; */
  }
  
  .analytics-plans-sidebar-header {
    display: flex;
    align-items: center;
    height: 69px;
    padding: 15px 0px;
    justify-content: center;
    background-color: #6038AF;
  }
  
  .analytics-plans-sidebar-header h2 {
    color: #ffffff;
    font-size: 22px;
  
    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
  
  .analytics-plans-sidebar-back-button {
    cursor: pointer;
    padding: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin-left 0.7s ease;
    position: relative;
    /* top: 6.5rem;
    left: 1.2rem; */
    top: 43vh;
    left: 93%;
    margin-left: 0px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }

  .v2   
  .analytics-plans-sidebar-back-button {
    cursor: pointer;
    padding: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin-left 0.7s ease;
    position: relative;
    /* top: 6.5rem;
    left: 3.5rem; */
    top: 43vh;
    left: 93%;
    margin-left: 0px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
  }
  
   .closed

    .analytics-plans-sidebar-back-button {
    left: 6rem;
  }
  
  .analytics-plans-sidebar-back-button i {
    color: #000000;
    height: 13px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .analytics-plans-sidebar-links {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .sidebar-linkss {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .sidebar-items-gap {
    gap: 10px
  }
  
  .analytics-plans-sidebar-links li .analytics-plans-sidebar-my-account-links {
    padding: 10px;
    cursor: pointer;
    display: flex;
    
    align-items: center;
    color: #323232;
    border-radius: 10px;
  }
  .analytics-plans-sidebar-my-account-links.active {
    background-color: #6038AF !important;
    color: #fff !important;
  }
  
  .analytics-plans-sidebar-btn-icons {
    /* color: #f0b100; */
    width: 25px;
    height: 25px;
  }
  
  .analytics-plans-sidebar-my-account-links.inactive {
    background-color: #fff;
    color: #323232;
  }
  .analytics-plans-sidebar-links
    li
    .analytics-plans-sidebar-my-account-links:hover {
    background-color: #ebf2ff !important;
    color: #323232 !important;
  }

  .sidebar-linkss
    li
    .analytics-plans-sidebar-my-account-links:hover {
    background-color: #ebf2ff !important;
    color: #323232 !important;
  }
  
  .sidebar-div {
    padding: 0px 15px;
    background: #fff;
  }

  .main-sidebar-div {
    /* padding: 20px 15px; */
    background: #fff;
  }
  
  /* Hide text when analytics-plans-sidebar is closed */
  .analytics-plans-sidebar.closed .analytics-plans-sidebar-links li span {
    display: none;
  }

  .sidebar-linkss li .analytics-plans-sidebar-my-account-links {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #323232;
    border-radius: 10px;
}

/* Hide text when analytics-plans-sidebar is closed */
.analytics-plans-sidebar.closed .sidebar-linkss li span {
    display: none;
}

  
  .analytics-plans-sidebar-btn-icons {
    width: 30px;
    height: 30px;
  }
  
  .analytics-plans-sidebar-header-bottom-line {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 0.5rem;
  }
  
  .analytics-plans-sidebar-header-ai-analytics-plans {
    position: relative;
    top: -25px;
    left: 60px;
    background-color: #076ac7;
    border-radius: 20px;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .analytics-li-title {
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #7d9bd6;
  }
  .subcustomer-tabel-select {
    font-size: 13px !important;
  }
  
  .subscription-header-container {
    background-color: #7B48E0;
    color: #fff !important;
  }
  