.bannerimage_container {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  height: 100%; /* Full height of the container */
  background-color: aliceblue;
}

.bannerimage_context {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100%; /* Fill the height of the container */
  text-align: center; /* Center text */
}

.button_tag:hover {
  background-color: #6038AF; /* Darker shade on hover */
  cursor: pointer; /* Optional: show pointer cursor */
}

.bottom_button {
  display: flex;
  justify-content: center; /* Center the button horizontally */
}

.button_tag {
  width: 250px;
  background-color: #6038AF;
  color: #ffffff;
  border-radius: 6px;
  padding: 7px;
  text-align: center;
}

.bannerimage_context {
  position: relative;
}

.bannerimage_context::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bannerimage_context > * {
  position: relative;
  z-index: 2; /* To ensure content appears above the overlay */
}
