.connection-container {
    background-color: #ffffff;
    display: flex;
}

.connection-main {
    /* padding: 100px; */
    margin: 80px;
    position: relative;
    display: flex;
    justify-content: end;
    width: 100%;
    max-height: 138vh;
    flex-direction: column;
    height: 50vh;
    background-color: #ffffff;

    @media screen and (max-width: 800px) {
        padding: 30px 20px;
    }
}

.connection-preview-container-main {
    height: 100%;
    overflow: hidden;
    /* overflow-y: scroll;
    scroll-behavior: smooth; */
}

.connection-preview-container {
    overflow-y: hidden;
}
