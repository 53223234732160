.chatbot-settings-container {
    display: flex;
}

.error-message {
    color: #ff0000; 
    font-size: 12px; 
    margin-left: 5px;
    display: block; 
    margin-top: 5px; 
}
.chatbot-settings-header {
    width: 100%;
}

.chatbot-settings-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 40px 40px 40px;
    width: 100%;
    max-height: 138vh;

    @media screen and (max-width: 800px) {
        padding: 30px 20px;
    }
}

.chatbot-settings-main-container {
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    width: 100%;
    border-radius: 15px;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.chatbot-settings-heading-section {
    border-bottom: 1px solid #999999;
    padding: 20px 20px 10px 20px;
    font-size: 28px;
    color: #000000;
    display: flex;
    align-items: start;
    padding: 10px;
}

.chatbot-settings-body-section {
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 15px;
}

.chatbot-settings-form-section {
    width: 50%;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.chatbot-settings-home-logout-icons {
    width: 45px;
    height: 45px;
    background-color: #6038AF;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.chatbot-settings-form h3 {
    font-size: 16px;
    font-weight: 500;
    color: #434343;
}

.chatbot-settings-form input {
    background-color: #FFFFFF;
    border: 1px solid #BFBFBF;
}

.chatbot-settings-form textarea {
    background-color: #FFFFFF;
    border: 1px solid #BFBFBF;
    resize: none;
}

.chatbot-settings-form-btn {
}