.dark {
    // Variables
    @import "./dark";

    // Utilities
    @import "./helper/utilities";

    // Reboot
    @import "./reboot/reboot";

    // Components
    @import "./components/accordion/custom-accordion";
    @import "./components/button/border-button";
    @import "./components/button/soft-btn";
    @import "./components/close/close";
    @import "./components/footer/footer";
    @import "./components/nav/navbar";
    @import "./components/alert/bs-alert";
    @import "./components/card/card";
    @import "./components/chart/chart";
    @import "./components/card/icon-box";
    @import "./components/form/form-control";
    @import "./components/form/form-wizard";
    @import "./components/form/modal";
    @import "./components/dropdown/dropdown";
    @import "./components/profile/profile";
    @import "./components/timeline/timeline-mega";
    @import "./components/widgets/credit-card";
    @import "./components/widgets/ribbon-effect";
    @import "./components/progressbar/progressbar";
    @import "./components/pagination/pagination";
    @import "./components/table/table";
    @import "./components/toast/toast";

    // Pages
    @import "./pages/kanban/kanban";
    @import "./pages/pricing/pricing";
    @import "./pages/auth/authentication";

    // Plugins
    @import "./plugins/chart";
    @import "./plugins/flatpicker";
    @import "./plugins/full-calendar";
    @import "./plugins/select-2";
    @import "./plugins/story";
    @import "./plugins/uppy";
    @import "./plugins/tour";
    @import "./plugins/quill";

    // Layouts
    // @import "./layout-style/dashboard_menu_style/boxed";
    @import "./layout-style/index";
 
    //Pro pages
    @import "./components/pro/pro-index";

 
    @import "./components/products/product";
    
}