::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}


::-webkit-scrollbar-thumb {
  background: #6038AF;
  -webkit-border-radius: 0.313em;
  border-radius: 0.313em;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 0.313em;
  border-radius: 0.313em;
}

/* ------second-common-scroll-style------- */
.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
.table-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* -----textarea-resize------------- */
.resize-textarea {
  resize: none;
}

/* -----input alert ------------- */
.required_message {
  position: absolute;
  color: red;
  text-transform: capitalize;
  padding: 0px 5px;
  font-size: 10.5px;
  font-weight: 600;
}

.bg_grey {
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  color: #555;
}

.fs_10 {
  font-size: 10px;
}

/* -----error message------------- */
.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-left: 5px;
  display: block;
  margin-top: 5px;
}

/* dashboard css */
.table_container {
  overflow-x: scroll;
  margin: 0 auto;
  min-height: 250px;
  /* max-height: 330px; */
  padding-bottom: 10px;
}

.chart_section_text {
  background: lightgray;
  padding: 3px 5px;
  border-radius: 3px;
}

.error_context {
  width: 99%;
  height: 94%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #f4f0f0d6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 58;
}

.dnone {
  display: none !important;
}

/* custome sidebar */
.custom-sidebar {
  background-color: #6038AF;
  color: #ffffff;
  transition: width 0.3s;
  position: relative;
  min-height: 138vh;
  height: auto;
}

.custom-sidebar-open {
  width: 370px;
}

.custom-sidebar-closed {
  width: 80px;
}

.custom-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 70px;
}

.sidebar-logo {
  width: 180px;
  height: auto;
  transition: width 0.3s;
}

.sidebar-logo-closed {
  width: 40px;
  height: auto;
  transition: width 0.3s;
}

.sidebar-toggle-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar-header-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.sidebar-divider {
  border: 1px solid #ffffff;
  margin: 10px 0;
}

.sidebar-header-text {
  position: absolute;
  text-align: center;
  border-radius: 50px;
  padding: 5px 15px;
  top: -15px;
  left: 30%;
  background-color: #6038AF;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
  margin: 2% 0 0 0;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  transition: background-color 0.3s;
  font-size: 1rem;
  border-radius: 4px;
  margin: 5px 0;
}

.sidebar-link:hover,
.sidebar-link-active {
  background-color: #f5b800;
  color: #6038AF;
}

.sidebar-icon {
  font-size: 1.5rem;
  margin-right: 10px;
  width: 35px;
}

.sidebar-link-label {
  font-size: 1rem;
}

.sidebar-section-title {
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  margin: 10px 0;
}

.borderless {
  border: none;
}

.signup-button {
  padding: 8px 16px;
  border-radius: 6px;
  outline: 0;
  border: 0;
  background: #6038AF;
  color: #fff;
  font-size: 16px;
}

.btn-success.btn:disabled,
.btn-success.btn.disabled,
fieldset:disabled .btn-success.btn {
  background: gray;
  border-color: gray;
}

.setting-select__control {
  background: #dee2e6;
}

/* * { border: 1px solid black; } */